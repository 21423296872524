import { isBrowser } from './utils';

export function clearKeyFromSessionStorage(key: string): void {
  if (!isBrowser()) {
    return;
  }
  try {
    window.sessionStorage.removeItem(key);
  } catch (err) {
    // Swallow
  }
}
