import { NextPage } from 'next';
import { Cookies } from 'react-cookie';

import { logError } from '@belong/logging';
import { COOKIES } from '@belong/constants';
import { ID, IFooterMenu, IHeaderMenu } from '@belong/types';
import { getAppProps } from '@belong/common-nextjs-server-side-props';
import { getCorrelationId } from '@belong/utils';
import { getFullServerOrClientUrl } from '@belong/url-utils';
import { getConfig } from '@belong/configs/next/config';
import FeaturesManager, { getFeatureToggles } from '@belong/feature-toggles';
import { getWebsiteConfig } from '@belong/contentful';

import { ErrorPageContent } from '../../contentBlocks';

interface IErrorPage {
  headerMenuContent?: IHeaderMenu;
  footerMenuContent?: IFooterMenu;
  correlationId?: string;
  statusCode?: number;
}

/**
 *
 * ErrorPageV2 is a component designed to work for apps using getServerSideProps, which
 * is a page level function that runs on the server side and returns all required page data to the _app.tsx and the page component.
 * One caveat is that _error.tsx currently does not support getServerSideProps, so we have to still rely on getInitialProps to get statusCode,
 * as well as fetch the contentful data for the global header and footer.
 */
export const ErrorPageV2: NextPage<IErrorPage> = props => {
  const { statusCode } = props;

  return <ErrorPageContent id={ID.CONTENT} statusCode={statusCode} />;
};

ErrorPageV2.getInitialProps = async ctx => {
  const { req, res, err } = ctx;
  const correlationId = getCorrelationId(ctx);
  const statusCode = res?.statusCode ?? err?.statusCode;

  const parsedCookie = new Cookies(req?.headers.cookie);
  const contentfulEnvironment = parsedCookie.get(COOKIES.CONTENTFUL_SETTINGS)?.environment;
  const [{ headerMenuContent, footerMenuContent }, features] = await Promise.all([
    getWebsiteConfig(contentfulEnvironment),
    getFeatureToggles(
      getConfig().publicRuntimeConfig.featureTogglesApiUrl,
      ctx?.req?.headers?.cookie,
      getAppProps(ctx).isBlue,
      correlationId
    )
  ]);

  FeaturesManager.init(correlationId, features);
  logError(err as Error);

  return {
    statusCode,
    correlationId,
    headerMenuContent,
    footerMenuContent,
    cookie: req?.headers.cookie ?? '',
    features,
    searchServerUrl: getFullServerOrClientUrl(ctx)
  };
};

ErrorPageV2.displayName = 'ErrorPageV2';
