import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import { ICON_NAMES } from '@belong/types';
import { BUTTON_VARIANTS } from '@belong/themes';
import { useLockBodyScroll } from '@belong/react-hooks/useLockBodyScroll';
import { isBrowser } from '@belong/env';

import { MODAL_TEST_IDS, BUTTON_TEST_ID } from '../../../helpers/testIds';
import Icon from '../../Icon/Icon';
import { ButtonWithRef } from '../../actions/Button/ButtonBase';
import { Columns, Column } from '../../ui';
import ButtonGroup from '../../styles/ButtonGroup';
import * as ModalStyles from './Modal.styles';
import { IModal, IModalShell, MODAL_STATES, IModalShellState, IModalComponentConfig } from './Modal.types';

const renderModalShell = ({
  image,
  primaryBtnLabel,
  secondaryBtnLabel,
  primaryAction,
  secondaryAction,
  isLoadingPrimary,
  isLoadingSecondary,
  state = MODAL_STATES.DEFAULT,
  children,
  primaryLink,
  secondaryLink,
  primaryBtnVariant = BUTTON_VARIANTS.PRIMARY,
  secondaryBtnVariant = BUTTON_VARIANTS.SECONDARY,
  hasHeading = false,
  ButtonComponent
}: IModalShell & IModalShellState & IModalComponentConfig): React.ReactNode => {
  return (
    <>
      <ModalStyles.DialogBody>
        {!hasHeading && image && (
          <ModalStyles.ImageWrapper>
            <ModalStyles.Image src={[image.src, { w: 150, h: 150 }]} alt={image.alt} contentType={image.contentType} />
          </ModalStyles.ImageWrapper>
        )}
        {children}
      </ModalStyles.DialogBody>
      {(primaryBtnLabel || secondaryBtnLabel || primaryLink || secondaryLink) && (
        <ModalStyles.DialogFooter>
          <ButtonGroup hasControl={{ xs: ['column', 'center', false], lg: ['row', 'center', true] }}>
            {primaryBtnLabel && (
              <ButtonComponent
                isLoading={isLoadingPrimary}
                disabled={isLoadingSecondary}
                type="button"
                variant={primaryBtnVariant}
                width={{ xs: 'full', lg: 'default' }}
                onClick={primaryAction}
                data-testid={`${state}-${BUTTON_TEST_ID.PRIMARY}`}
              >
                {primaryBtnLabel}
              </ButtonComponent>
            )}
            {secondaryBtnLabel && (
              <ButtonComponent
                type="button"
                isLoading={isLoadingSecondary}
                disabled={isLoadingPrimary}
                variant={secondaryBtnVariant}
                width={{ xs: 'full', lg: 'default' }}
                onClick={secondaryAction}
                data-testid={`${state}-${BUTTON_TEST_ID.SECONDARY}`}
              >
                {secondaryBtnLabel}
              </ButtonComponent>
            )}
            {primaryLink && <ButtonComponent width={{ xs: 'full', sm: 'min' }} {...primaryLink} />}
            {secondaryLink && <ButtonComponent width={{ xs: 'full', sm: 'min' }} {...secondaryLink} />}
          </ButtonGroup>
        </ModalStyles.DialogFooter>
      )}
    </>
  );
};

const BaseModal: React.FC<IModal & IModalShellState> = ({
  onClose,
  isOpen,
  state = MODAL_STATES.DEFAULT,
  defaultObject,
  successObject,
  errorObject,
  primaryBtnLabelInProgress,
  defaultHeading,
  defaultContent,
  successContent,
  errorContent,
  ariaLabelClose = 'Close dialog',
  ariaDialogLabelledBy,
  ariaDialogDescribedby,
  ButtonComponent = ButtonWithRef
}: IModal & IModalShellState) => {
  // This value will be reset by the useEffect below
  const [isServer, setIsServer] = useState(true);

  const isLoadingPrimary = state === MODAL_STATES.LOADING_PRIMARY;
  const isLoadingSecondary = state === MODAL_STATES.LOADING_SECONDARY;
  const isSuccess = state === MODAL_STATES.SUCCESS;
  const isError = state === MODAL_STATES.ERROR;
  const isDefault = state === MODAL_STATES.DEFAULT;
  const hasHeading = !!defaultHeading;

  const handleEscape = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  // BEGIN :HAS() HACK

  const supportsHas = isBrowser() && CSS.supports && CSS.supports('selector(:has(.foo))');
  useLockBodyScroll(!supportsHas && isOpen);

  // END :HAS() HACK

  useEffect(() => {
    window.addEventListener('keydown', handleEscape);
    setIsServer(false);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [handleEscape]);

  if (isServer) {
    return null;
  }

  return ReactDOM.createPortal(
    <FocusTrap>
      <ModalStyles.Dialog
        open
        role="dialog"
        aria-modal
        aria-labelledby={ariaDialogLabelledBy}
        aria-describedby={ariaDialogDescribedby}
        data-testid={MODAL_TEST_IDS.ROOT}
      >
        <ModalStyles.InnerContainer data-testid={MODAL_TEST_IDS.CONTENTS}>
          <Columns alignX="center">
            <Column width={{ md: '5/6', lg: '2/3' }}>
              <ModalStyles.DialogBox data-testid={MODAL_TEST_IDS.DIALOG_BOX} state={state} isOpen={isOpen}>
                {(isLoadingPrimary || isLoadingSecondary) && <ModalStyles.DialogBoxOverlay />}
                <ModalStyles.DialogHeader hasHeading={hasHeading}>
                  {hasHeading && defaultObject?.image && (
                    <ModalStyles.ImageWrapper>
                      <ModalStyles.Image
                        src={[defaultObject?.image.src, { w: 150, h: 150 }]}
                        alt={defaultObject?.image.alt}
                        contentType={defaultObject?.image.contentType}
                      />
                    </ModalStyles.ImageWrapper>
                  )}
                  {hasHeading && defaultObject?.topIcon && <Icon {...defaultObject?.topIcon} />}
                  {defaultHeading}
                  <ModalStyles.CloseButton
                    isSmall
                    onClick={onClose}
                    data-testid={MODAL_TEST_IDS.CLOSE_BUTTON}
                    role="button"
                    a11yLabel={ariaLabelClose}
                    hasHeading={hasHeading}
                  >
                    <Icon aria-hidden name={ICON_NAMES.Close} size="small" />
                  </ModalStyles.CloseButton>
                </ModalStyles.DialogHeader>
                {(isDefault || isLoadingPrimary || isLoadingSecondary) &&
                  renderModalShell({
                    image: defaultObject?.image,
                    children: defaultContent,
                    primaryBtnLabel:
                      isLoadingPrimary && primaryBtnLabelInProgress
                        ? primaryBtnLabelInProgress
                        : defaultObject?.primaryBtnLabel,
                    secondaryBtnLabel: defaultObject?.secondaryBtnLabel,
                    primaryAction: defaultObject?.primaryAction,
                    secondaryAction: defaultObject?.secondaryAction,
                    primaryBtnVariant: defaultObject?.primaryBtnVariant,
                    secondaryBtnVariant: defaultObject?.secondaryBtnVariant,
                    primaryLink: defaultObject?.primaryLink,
                    secondaryLink: defaultObject?.secondaryLink,
                    isLoadingPrimary,
                    isLoadingSecondary,
                    hasHeading,
                    ButtonComponent
                  })}
                {isSuccess &&
                  renderModalShell({
                    image: successObject?.image,
                    children: successContent,
                    primaryBtnLabel: successObject?.primaryBtnLabel,
                    secondaryBtnLabel: successObject?.secondaryBtnLabel,
                    primaryAction: successObject?.primaryAction,
                    secondaryAction: successObject?.secondaryAction,
                    primaryLink: successObject?.primaryLink,
                    secondaryLink: successObject?.secondaryLink,
                    state,
                    hasHeading,
                    ButtonComponent
                  })}
                {isError &&
                  renderModalShell({
                    image: errorObject?.image,
                    children: errorContent,
                    primaryBtnLabel: errorObject?.primaryBtnLabel,
                    secondaryBtnLabel: errorObject?.secondaryBtnLabel,
                    primaryAction: errorObject?.primaryAction,
                    secondaryAction: errorObject?.secondaryAction,
                    primaryLink: errorObject?.primaryLink,
                    secondaryLink: errorObject?.secondaryLink,
                    state,
                    hasHeading,
                    ButtonComponent
                  })}
              </ModalStyles.DialogBox>
            </Column>
          </Columns>
        </ModalStyles.InnerContainer>
      </ModalStyles.Dialog>
    </FocusTrap>,
    document.body
  );
};

BaseModal.displayName = 'BaseModal';
export default BaseModal;
