import { ContentfulImageOptions, BasicImageSource, ENVS, ResponsiveImageSource, TBreakpointKeys } from '@belong/types';
import { BREAKPOINTS, BREAKPOINTS_MAX_WIDTH } from '@belong/themes';

import { getCurrentEnvironment } from '@belong/env';
import Picture from '../../styles/Picture';

import { IPictureContainer, ResponsiveSrcKey } from './PictureContainer.types';

const PictureContainer: React.FC<IPictureContainer> = ({
  isOptimized = true,
  alt,
  src,
  contentType,
  isPresentation,
  className,
  loading,
  imageAriaHidden
}: IPictureContainer) => {
  if (getCurrentEnvironment() === ENVS.DEVELOPMENT) {
    if (isPresentation) {
      // eslint-disable-next-line no-console
      console.warn(
        'Deprecated usage of `isPresentation` property on <PictureContainer> component. Just pass a falsy `alt` text prop instead (or omit `alt` entirely).'
      );
    }
  }

  const performanceEnhancements = (options: ContentfulImageOptions): any => {
    const newOptions: ContentfulImageOptions = { fm: 'webp' };

    if (contentType === 'svg') {
      return {};
    }

    if (options.w || options.h) {
      newOptions.q = 70;
    }

    if (options.w) {
      newOptions.w = 2 * options.w;
    }

    if (options.h) {
      newOptions.h = 2 * options.h;
    }

    return { ...options, ...newOptions };
  };

  const decorativeAttributes = (): { alt: string; 'aria-hidden'?: boolean } => {
    const attribute = {
      alt: alt || ''
    };
    if (imageAriaHidden) {
      return { ...attribute, 'aria-hidden': true };
    }
    return attribute;
  };

  const srcToString = ([url, options]: BasicImageSource): string => {
    if (url === '') {
      return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    }

    const newOptions = isOptimized ? performanceEnhancements(options) : options;

    if (Object.keys(newOptions).length === 0) {
      return url;
    }

    return `${url}?${Object.keys(newOptions)
      .map(k => `${k}=${newOptions[k as keyof ContentfulImageOptions]}`)
      .join('&')}`;
  };

  const compileResponsiveSrc = (srcObject: ResponsiveImageSource): ResponsiveSrcKey[] => {
    const srcKeys = Object.keys(srcObject);
    const lastKeyIndex = srcKeys.length - 1;

    return srcKeys.map((k, index) => {
      const key = k as TBreakpointKeys;
      const source = srcObject[key];
      const media =
        index !== lastKeyIndex && key !== 'xl'
          ? `(max-width: ${BREAKPOINTS_MAX_WIDTH[key] / 16}em)`
          : `(min-width: ${BREAKPOINTS[key] / 16}em)`;

      return {
        media,
        src: (source && srcToString(source)) || ''
      };
    });
  };

  const compileSrc = (): any => {
    if (Array.isArray(src)) {
      return srcToString(src);
    }

    return compileResponsiveSrc(src);
  };

  const source = compileSrc();
  const decorativeAttrs = decorativeAttributes();

  return <Picture loading={loading} className={className} source={source} {...decorativeAttrs} />;
};

PictureContainer.displayName = 'PictureContainer';
export { PictureContainer };
