import * as React from 'react';

import { ID, IGlobalHeaderUrls, IPageAlert, USER_TYPE, IHeaderMenu } from '@belong/types';
import { isBrowser } from '@belong/env';
import { urls } from '@belong/url-utils';
import { useLockBodyScroll } from '@belong/react-hooks';
import { BackToTopTrigger, SkipLink } from '@belong/ui-components';
import { GlobalHeaderMobile } from '../GlobalHeaderMobile';
import { GlobalHeaderDesktop } from '../GlobalHeaderDesktop';
import { ContentStretcher } from './styles';

export interface IGlobalHeader {
  headerMenuContent: IHeaderMenu;
  currentUrl?: string;
  isLoggedIn: boolean;
  userType?: USER_TYPE;
  pageAlert?: IPageAlert;
  showBackToTopTrigger: boolean;
  // hide the 'My services' and 'My profile' links from the header.
  hideMyAccountLinks?: boolean;
}

const HEADER_URLS: IGlobalHeaderUrls = {
  home: urls.HOME,
  blog: urls.BLOG,
  coverageMap: urls.COVERAGE_MAP,
  dataPlans: urls.MOBILE_DATA_PLANS,
  login: urls.LOGIN,
  mobilePlans: urls.MOBILE_PLANS,
  myProfile: urls.MY_PROFILE,
  myServices: urls.MY_SERVICES,
  nbnPlans: urls.NBN_PLANS,
  internationalRoaming: urls.INTERNATIONAL_ROAMING,
  ourMobileNetwork: urls.OUR_MOBILE_NETWORK,
  secondLifeDevices: urls.SECOND_LIFE_DEVICES,
  support: urls.SUPPORT,
  search: urls.SEARCH
};

export const GlobalHeader: React.FC<React.PropsWithChildren<IGlobalHeader>> = ({
  headerMenuContent,
  currentUrl,
  isLoggedIn,
  userType,
  pageAlert,
  showBackToTopTrigger,
  hideMyAccountLinks,
  children
}) => {
  const [isMobileExpanded, setIsMobileExpanded] = React.useState(false);
  const [isDesktopExpanded, setIsDesktopExpanded] = React.useState(false);

  // BEGIN :HAS() HACK

  const supportsHas = isBrowser() && CSS.supports && CSS.supports('selector(:has(.foo))');
  useLockBodyScroll(!supportsHas && isMobileExpanded);

  // END :HAS() HACK

  return (
    <>
      {headerMenuContent.a11ySkipToContent && (
        <SkipLink href={`#${ID.CONTENT}`}>{headerMenuContent.a11ySkipToContent}</SkipLink>
      )}
      {showBackToTopTrigger && !isMobileExpanded && <BackToTopTrigger />}

      <>
        <GlobalHeaderMobile
          headerMenuContent={headerMenuContent}
          currentUrl={currentUrl}
          isLoggedIn={isLoggedIn}
          pageAlert={pageAlert}
          hideMyAccountLinks={hideMyAccountLinks}
          urls={HEADER_URLS}
          onExpanded={x => setIsMobileExpanded(x)}
        />
        <GlobalHeaderDesktop
          headerMenuContent={headerMenuContent}
          currentUrl={currentUrl}
          isLoggedIn={isLoggedIn}
          userType={userType}
          pageAlert={pageAlert}
          hideMyAccountLinks={hideMyAccountLinks}
          urls={HEADER_URLS}
          onExpanded={x => setIsDesktopExpanded(x)}
        />
      </>
      <ContentStretcher
        aria-hidden={isMobileExpanded || isDesktopExpanded}
        tabIndex={isMobileExpanded || isDesktopExpanded ? -1 : undefined}
      >
        {children}
      </ContentStretcher>
    </>
  );
};

GlobalHeader.displayName = 'GlobalHeader';
