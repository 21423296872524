import { useState, useEffect } from 'react';
import { isBrowser } from '@belong/env';

import createProvider from '../_factory';

type ProductsList = any[];

interface IProvider {
  products: ProductsList | null;
  setProducts: (newValue: ProductsList) => void;
}

export interface IProducts {
  products: [any];
}

type hook = () => /* this hook takes no args */ IProvider;

const readFromStorage = (): ProductsList => {
  if (isBrowser()) {
    const value = localStorage.getItem('products');
    if (value) {
      return JSON.parse(value) || [];
    }
  }

  return [];
};

const storeIntoStorage = (products: ProductsList): void => {
  if (isBrowser()) {
    localStorage.setItem('products', JSON.stringify(products));
  }
};

const useProducts: hook = () => {
  const [products, setProducts] = useState<ProductsList | null>(readFromStorage());

  useEffect(() => {
    if (products) {
      storeIntoStorage(products);
    }
  }, [products]);

  return {
    products,
    setProducts
  };
};

const { hook, provider } = createProvider<hook>(useProducts, 'useProducts');

export const Provider = provider;

export default hook;
