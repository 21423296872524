import { applyDictionaryToObject, IOptionalStringMap, isString } from '@belong/utils';
import { compile } from '@belong/url-utils';
import urlParse, { qs } from 'url-parse';
import type { UrlObject } from 'url';

interface ICompileLinkProps {
  href: string;
  currentPath: string;
  urlParams: IOptionalStringMap;
  dictionary: Record<string, string | undefined>;
  query?: Record<string, string | string[]>;
}

/**
 * Inherited issues:
 * - removes banned params from dest url query-string (serviceId, orderId, outageId)
 *   These are commonly used to replace url tokens, but often multiple values are chucked in even if they are not required
 *   The current logic will only remove values from the query that are used to replace url tokens, so without the bannedParams
 *   these params will be appended to the dest url.
 */
const bannedParams = ['serviceId', 'orderId', 'outageId'];

interface ILinkProps {
  href: UrlObject;
}

export const compileLinkProps = ({
  href,
  currentPath,
  urlParams,
  dictionary,
  query = {}
}: ICompileLinkProps): ILinkProps => {
  const urlValue = href;
  const decoratedUrl = urlParse(compile(urlValue, urlParams));
  const allParams = applyDictionaryToObject(dictionary, {
    ...qs.parse(decoratedUrl.query),
    ...(query as Record<string, string>) // TODO: re-write to avoid needing this type override
  });
  (decoratedUrl as any).query = Object.keys(allParams)
    .filter(key => !new RegExp(`/:${key}(/|$)`).test(urlValue) && !bannedParams.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: allParams[key] }), {});

  const linkProps = {
    href: {
      pathname: decoratedUrl.pathname || currentPath,
      query: decoratedUrl.query,
      hash: decoratedUrl.hash
    }
  };

  return linkProps;
};

/**
 * TODO: find a way to get the framework to do this for us instead of having to manually re-create the href
 */
export const makeHrefFromLinkProps = (linkProps: ILinkProps): string => {
  const hasHash = !!linkProps.href.hash;
  let result = linkProps.href.pathname ?? '';
  const { query } = linkProps.href;

  if (query) {
    if (isString(query) && query.length > 0) {
      result += `?${query}`;
    } else {
      const search = new URLSearchParams(query as any);
      if ([...search.values()].length) {
        result += `?${search.toString()}`;
      }
    }
  }

  if (hasHash) {
    result += `#${linkProps.href.hash}`;
  }

  return result;
};
