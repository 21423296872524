import { useEffect } from 'react';

interface IProps {
  parent?: HTMLElement | null;
  shouldFocus?: boolean;
  selector: string;
}

export const useAutoFocusHeading = ({ parent, shouldFocus = true, selector }: IProps): void => {
  useEffect(() => {
    if (shouldFocus && selector) {
      const heading = (parent || document.body).querySelector<HTMLElement>(selector);
      heading?.focus();
    }
  }, [shouldFocus, parent, selector]);
};
