import { useState } from 'react';
import { hasAdBlocker } from '@belong/env';
import createProvider from '../_factory';

export interface IDeviceCapabilities {
  hasAdBlocker: boolean;
  supportsWebP: boolean;
}

type DeviceCapabilityKey = keyof IDeviceCapabilities;

interface IProvider {
  capabilities: IDeviceCapabilities;
  setCapability: (name: DeviceCapabilityKey, value: string | number | boolean) => void;
}

type hook = (/* this hook takes no args */) => IProvider;

const useDeviceCapabilities: hook = () => {
  const [capabilities, setCapabilities] = useState<IDeviceCapabilities>({
    hasAdBlocker: hasAdBlocker(),
    supportsWebP: true
  });

  const setCapability = (name: DeviceCapabilityKey, value: string | number | boolean): void => {
    setCapabilities({
      ...(capabilities as IDeviceCapabilities),
      [name]: value
    });
  };

  return { capabilities, setCapability };
};

const { hook, provider } = createProvider<hook>(useDeviceCapabilities, 'useDeviceCapabilities');

export const Provider = provider;
export default hook;
