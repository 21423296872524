import {
  XAlignKey,
  ColoursKey,
  DirectionKey,
  FlairsKey,
  FontColoursKey,
  FontNameKey,
  FontWeightKey,
  GradientsKey,
  GutterBreakpointKey,
  ShadowsKey,
  TBreakpointKeys,
  ZIndexKey,
  YAlignKey,
  ButtonVariantKey,
  ButtonXAlignKey,
  XAlignValue,
  FlexAlignValue,
  DirectionValue,
  ButtonVariantValue,
  FlairsValue,
  ButtonWidthKey,
  ColoursValue,
  CalloutFlairsKey,
  SpacesKeys,
  ITheme
} from '@belong/types';

export const FONT_NAME: Record<FontNameKey, string> = {
  PROXIMA_NOVA: 'ProximaNova'
};

export const FONT_WEIGHT: Record<FontWeightKey, number> = {
  BOLD: 700,
  SEMI_BOLD: 600,
  REGULAR: 400
};

export const COLOURS: Record<ColoursKey, ColoursValue> = {
  WHITE_OVERLAY: 'rgba(255, 255, 255, 0.66)',
  BLACK_OVERLAY: 'rgba(0, 0, 0, 0.75)',

  WHITE: '#FFF',
  GREY_100: '#F8F8F8',
  GREY_200: '#EBECED',
  GREY_300: '#D2D4D6',
  GREY_400: '#B3B3B3',
  GREY_500: '#6B6B6B',
  GREY_600: '#404040',
  GREY_700: '#1B1B1B',
  BLACK: '#000000',

  LIGHT_BLUE: '#DFF3FB',
  MID_BLUE: '#B2D6EB',
  DARK_BLUE: '#0061AB',

  LIGHT_GREEN: '#E0F2F1',
  MID_GREEN: '#C2E1D4',
  BRIGHT_GREEN: '#0acf83',
  DARK_GREEN: '#00794D',

  YELLOW: '#FFF200',

  LIGHT_ORANGE: '#FFF4E3',
  MID_ORANGE: '#FFB38A',
  ALT_ORANGE: '#E8834D',
  DARK_ORANGE: '#C74500',

  LIGHT_RED: '#FFECF1',
  MID_RED: '#EFA5AD',
  ALT_RED: '#DE4B5A',
  RED: '#DC3545',
  DARK_RED: '#C02333',

  PURPLE: '#CC00CC',
  DARK_PURPLE: '#410075',

  // Named/brand colours

  BELONG_PRIMARY: '#000000',

  BELONG_BLUE_LIGHT: '#58CCFB',
  BELONG_BLUE: '#41B6E6',
  BELONG_BLUE_DARK: '#1F9DD2',

  DISCO_PINK: '#FFC9FF',
  DISCO_PINK_DARK: '#FF4BFF',

  MOSAIC_LIGHT: '#F8EFFF',
  PASSION_POP: '#ECD6FF',
  PASSION_POP_DARK: '#C175FF',
  MOSAIC: '#A538FF',

  PEPPERMINT_CRISP: '#C3FBE2',
  PEPPERMINT_CRISP_DARK: '#38F29E'
};

export const FLAIRS: Record<FlairsKey, FlairsValue> = {
  PASSION_POP: 'PASSION_POP',
  DISCO_PINK: 'DISCO_PINK',
  PEPPERMINT_CRISP: 'PEPPERMINT_CRISP',
  BLACK: 'BLACK',
  FOIL_SLICK: 'FOIL_SLICK',
  ELECTRIC_DREAM: 'ELECTRIC_DREAM',
  SANDY_SALMON: 'SANDY_SALMON',
  TOXIC_SLUDGE: 'TOXIC_SLUDGE',
  BLUE: 'BLUE',
  UNAVAILABLE: 'UNAVAILABLE',
  GREEN: 'GREEN'
};

export const FLAIR_COLOURS: Record<FlairsKey, string> = {
  PASSION_POP: 'linear-gradient(90deg, #41B6E6 0%, #C175FF 100%)',
  DISCO_PINK: COLOURS.DISCO_PINK,
  ELECTRIC_DREAM: 'linear-gradient(90deg, #41B6E6 0%, #3B7DFC 100%)',
  SANDY_SALMON: 'linear-gradient(270deg, #FFB177 0%, #41B6E6 100%)',
  TOXIC_SLUDGE: 'linear-gradient(270deg, #C7F238 0%, #41B6E6 100%)',
  PEPPERMINT_CRISP: 'linear-gradient(270deg, #38F29E 0%, #41B6E6 100%)',
  BLACK: COLOURS.BLACK,
  FOIL_SLICK: 'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
  BLUE: COLOURS.BELONG_BLUE,
  UNAVAILABLE: COLOURS.GREY_300,
  GREEN: COLOURS.MID_GREEN
};

export const CALLOUT_FLAIR_COLOURS: Record<
  Exclude<CalloutFlairsKey, 'ELECTRIC_DREAM' | 'SANDY_SALMON' | 'TOXIC_SLUDGE'>,
  string
> = {
  PASSION_POP: COLOURS.PASSION_POP,
  DISCO_PINK: COLOURS.DISCO_PINK,
  PEPPERMINT_CRISP: COLOURS.PEPPERMINT_CRISP,
  BLACK: COLOURS.BLACK,
  FOIL_SLICK:
    'linear-gradient(249.19deg, #D2F560 17.27%, #60F5B1 28.61%, #67C5EB 42.93%, #CD91FF 63.36%, #FF6FFF 76.95%, #FFC192 89.3%);',
  UNAVAILABLE: COLOURS.GREY_300,
  GREEN: COLOURS.MID_GREEN
};

export const GRADIENTS: Record<GradientsKey, string> = {
  ACCENT_STRIP_GLOBAL_HEADER: `linear-gradient(
    269.99deg,
    #d0ef5d 0.66%,
    #78eea5 16.31%,
    #62b4e1 36.66%,
    #b67cf7 66.4%,
    #eb5ef7 83.62%,
    #f4b480 100.83%
  )`
};

export const FONT_COLOURS: Record<FontColoursKey, ColoursValue> = {
  ACCENT: COLOURS.YELLOW,
  ACTIVE: COLOURS.BELONG_BLUE,
  BASE: COLOURS.GREY_600,
  DISABLED: COLOURS.GREY_400,
  ERROR: COLOURS.DARK_RED,
  ERROR_ON_DARK: COLOURS.ALT_RED,
  FINE_PRINT: COLOURS.GREY_500,
  LIGHT: COLOURS.WHITE,
  MESSAGE: COLOURS.DARK_BLUE,
  STRONG: COLOURS.BLACK,
  SUCCESS: COLOURS.DARK_GREEN,
  WARNING: COLOURS.DARK_ORANGE,
  EMPHASIZE: COLOURS.PURPLE,
  MOSAIC: COLOURS.MOSAIC
};

// See Belong Design System for explanation of shadow names
// https://www.figma.com/file/jQMc7AYX3M3dUPCqV7k49y/Styles?node-id=839%3A1435
export const SHADOWS: Record<ShadowsKey, string> = {
  KEY_LIGHT: '0px 1px 7px rgba(0, 0, 0, 0.2)',
  AMBIENT_LIGHT: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  HOVER: '0px 5px 13px 1px rgba(0, 0, 0, 0.2)'
};

export const BREAKPOINTS: Record<TBreakpointKeys, number> = {
  xs: 0,
  sm: 416, // 25.93em
  md: 792, // 49.5em
  lg: 1024, // 64em
  xl: 1440 // 90em
};

// TBC: see if this can be combined with BREAKPOINTS_MAX_WIDTH from app
export const BREAKPOINTS_MAX_WIDTH: Record<Exclude<TBreakpointKeys, 'xl'>, number> = {
  xs: BREAKPOINTS.sm - 1, // 25.87em
  sm: BREAKPOINTS.md - 1, // 49.4em
  md: BREAKPOINTS.lg - 1, // 63.9em
  lg: BREAKPOINTS.xl - 1 // 89.9em
};

const STANDARD_SPACING: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  xs: '1.6rem',
  md: '2.4rem'
};

export const CONTAINER_MAX_WIDTHS: Record<Exclude<TBreakpointKeys, 'xs' | 'sm'>, string> = {
  md: '80.4rem',
  lg: '93.6rem',
  xl: '131.2rem'
};

export const GRID_GUTTERS: Record<GutterBreakpointKey, string> = {
  xs: '0.8rem', // visible gutter is (2 * 0.8rem)
  md: '1.2rem',
  lg: '1.6rem'
};

export const GRID_VERTICAL_GUTTERS: Record<GutterBreakpointKey, string> = {
  ...STANDARD_SPACING,
  lg: '3.2rem'
};

export const SPACES: Record<SpacesKeys, number> = {
  none: 0,
  xxsmall: 0.4,
  xsmall: 0.8,
  small: 1.2,
  medium: 1.6,
  large: 2.4,
  xlarge: 3.2,
  xxlarge: 4.8,
  xxxlarge: 7.2
};
export const cssUnit: ITheme['cssUnit'] = 'rem';

export const SECTION_VERTICAL_SPACINGS: Record<GutterBreakpointKey, string> = {
  xs: '3.2rem',
  md: '4.8rem',
  lg: '7.2rem'
};

export const SECTION_VERTICAL_INNER_SPACINGS: Record<GutterBreakpointKey, string> = {
  xs: '2.4rem',
  md: '3.2rem',
  lg: '4rem'
};

export const TILE_INNER_PADDING: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  ...STANDARD_SPACING
};

export const TILE_VERTICAL_INNER_SPACINGS: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  ...STANDARD_SPACING
};

export const INPUTS_WRAPPER_VERTICAL_SPACING: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  ...STANDARD_SPACING
};

export const FIELDSET_VERTICAL_SPACING = '0.8rem';

export const Z_INDEX: Record<ZIndexKey, number> = {
  PAGE_TITLE: -1,
  LIST_BOX: 505,
  SEARCH_BOX: 500,
  SEARCH_BOX_PROGRESS_BAR: 501,
  CARD_BUSY_OVERLAY: 1000,
  CARD_BUSY_PROGRESS_BAR: 1010,
  GLOBAL_HEADER: 1500,
  SKIP_LINK: 1700,
  MODAL: 1900,
  PROGRESS_INDICATOR: 2000,
  NATIVE_NAVIGATION: 3000
};

export const DIRECTION: Record<DirectionKey, DirectionValue> = {
  row: 'row',
  column: 'column',
  'row-reverse': 'row-reverse',
  'column-reverse': 'column-reverse'
};

export const ALIGNMENT: Record<XAlignKey, XAlignValue> = {
  left: 'left',
  center: 'center',
  right: 'right'
};

export const FLEX_ALIGN: Record<XAlignKey, FlexAlignValue> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

export const FLEX_ALIGN_REVERSE: Record<XAlignKey, FlexAlignValue> = {
  left: 'flex-end',
  center: 'center',
  right: 'flex-start'
};

export const FLEX_ALIGN_VERTICAL: Record<YAlignKey, FlexAlignValue> = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};

// TBC: determine the usage of this enum. this and variant type used interchangeably
export const BUTTON_VARIANTS: Record<ButtonVariantKey, ButtonVariantValue> = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary'
};

export const BUTTON_WIDTHS: Record<ButtonWidthKey, ButtonWidthKey> = {
  default: 'default',
  full: 'full',
  half: 'half',
  min: 'min'
};

// TBC: determine the usage of this enum. this and alignment type used interchangeably
export const BUTTON_ALIGNMENT: Record<ButtonXAlignKey, string> = {
  CENTER: 'center',
  RIGHT: 'right'
};

export const BORDER_RADIUS = {
  BUTTON: '4rem',
  CARD: '0.8rem',
  INPUT: '0.4rem'
} as const;

export const FOCUS_RING_WIDTH = 0.2; // (in rems)
