export const COOKIES = {
  AUTOMATED_BROWSER_TESTING: 'automated-browser-testing',
  BELONG: 'belong',
  BELONG_AGENT_CUSTOMER: 'belongAgentCustomer',
  CONTENT_LOOKUP: 'ContentLookup',
  CONTENTFUL_SETTINGS: 'contentful_settings',
  DISMISSED_ALERTS: 'dismissedAlerts',
  IDV_SESSION: 'idvSession',
  MFA_ENROL: 'belong-mfa-enrol',
  NATIVE_APP: 'uiIdiom',
  DEVICE_CAPABILITIES: 'deviceCapabilities',
  MOBO_AUTH_ID: 'moboAuthId',
  MOBO_EMAIL: 'moboEmail',
  MOBO_LAST_NAME: 'moboLastName',
  MOBO_FIRST_NAME: 'moboFirstName',
  MOBO_MOBILE_UTILIBILL_ID: 'moboMobileUtilibillId',
  MOBO_UTILIBILL_ID: 'moboUtilibillId',
  NBN_PROMO: 'nbnPromo',
  SESSION: 'session',
  UNIQUE_IDENTIFIER: 'uniqueIdentifier',
  WEBP: 'BELONG_WEBP',
  WHICH_APPLICATION: 'which_application',
  NDC: 'ndc'
} as const;
