import { getConfig } from '@belong/configs/next/config';
import { isBrowser } from '@belong/env';

let analytic: AnalyticEvent;

type TDetail = { action: 'API'; endpoint: string; moment: string; value: object } | { action: string; data: object };

export default class AnalyticEvent {
  publicRuntimeConfig;
  isEnabled: boolean;

  constructor() {
    const { publicRuntimeConfig } = getConfig();
    this.publicRuntimeConfig = publicRuntimeConfig;
    this.isEnabled = publicRuntimeConfig.analytic?.shouldRun;
  }

  dispatchEvent(eventName: string, detail: TDetail): void {
    if (!isBrowser() || !this.isEnabled) {
      return;
    }
    const evtDetail = { ...detail, workspace: this.publicRuntimeConfig.workspace };
    window.dispatchEvent(new CustomEvent(eventName, { detail: evtDetail }));
  }

  trackAPI(endpoint: string, moment: 'BEFORE' | 'AFTER', value: object): void {
    this.dispatchEvent('trackAPI', { endpoint, moment, value, action: 'API' });
  }
}

export function initAnalytic(): AnalyticEvent {
  if (!analytic) {
    analytic = new AnalyticEvent();
  }

  return analytic;
}
