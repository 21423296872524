export const ANALYTICS_STRINGS = {
  ACTION: {
    ADD_TO_CART: 'add_to_cart',
    BUTTON_HIGHLIGHT: 'button highlight',
    CHECKED: 'checked', // user 'checks' a radio button or checkbox
    CHECKOUT_STEP: 'checkout step',
    CHOICE_CHIP_SELECTION: 'choice-chip selection',
    CLICK: 'click',
    DISPLAY: 'display',
    ERROR: 'error',
    FIELD_ERROR: 'field error',
    FIELD_FOCUS: 'field focus',
    INPUT_BLUR_ERROR: 'blur error',
    LOADING: 'loading',
    OPEN_MODAL: 'open modal',
    PAGE_LOAD: 'page load',
    PURCHASE: 'purchase',
    SAVED: 'saved',
    SELECT: 'select', // user selects an option in a dropdown
    SERVER_ERROR: 'server error',
    SETUP: 'nbn setup',
    STEP_CHANGE: 'stepChange',
    SUCCESS: 'success',
    UNCHECKED: 'unchecked', // user 'unchecks' a radio button or checkbox
    VALUE_ENTERED: 'value entered'
  },
  CATEGORY: {
    ALERT_BANNER: 'alert banner',
    API_RESPONSE: 'api response',
    ECOMMERCE: 'ecommerce',
    ELEMENT_VISIBILITY: 'element visibility',
    ELEMENT_VISIBLE: 'element Visible',
    EXTERNAL_LINK: 'external link',
    FORM: 'form',
    INTERACTION: 'interaction',
    INTERNAL_LINK: 'internal link',
    MODAL: 'modal',
    NBN_ADDRESS_INFO: 'nbn_address_info',
    ORDER_PROGRESS: 'order-progress',
    OUTAGE: 'outage',
    PAGE_ENGAGEMENT: 'page engagement', // user interacts with a page such as click/keyboard input/etc.
    PAGEVIEW: 'pageview',
    SERVICE_CARD: 'serviceCard'
  },
  COMPONENT: {
    BUTTON: 'Button',
    CHECKBOX: 'checkbox',
    CHOICE_CHIP: 'choice chip',
    DROPDOWN: 'dropdown',
    MODAL: 'modal',
    PLAN_CARD: 'plan card',
    RADIO: 'radio'
  },
  FLOW: {
    BOBO: 'bobo',
    NBNJF: 'nbn join flow',
    NBNPR: 'nbn plan refresh flow', // sign up to NBN flow
    NBNMV: 'nbn move flow'
  },
  FORM: {
    NBN_IMPORTANT_TERMS: 'nbn order important terms',
    NBN_MOBILE: 'nbn order mobile capture',
    NBN_MODEM: 'nbn plan refresh modem charge',
    NBN_NDC: 'nbn plan refresh new development charge',
    NBN_PAYMENT: 'nbn order payment method',
    NBN_MODEM_DELIVERY: 'nbn modem delivery',
    NBN_SERVICE_CONNECTION: 'nbn service connection',
    NBN_NETWORK_CONNECTION_DEVICE: 'nbn network connection device'
  },
  LABEL: {
    STEP_CHANGE: 'Stepper State Change',
    CUSTOMER: 'customer'
  },
  JOURNEY: {
    ENTER: 'Enter'
  },
  PAGENAME: {
    IMPORTANT_INFORMATION: 'important information',
    MOBILE_NUMBER: 'mobile number',
    MODEM_CHARGES: 'Modem Charges',
    NDC: 'New Development Charges',
    PAYMENT_CAPTURE: 'Payment Capture',
    MODEM_DELIVERY: 'Modem Delivery address'
  },
  MODEM_CHOICE: {
    MODEM_DELIVERY: 'Modem Delivery',
    BONUS_SIM: 'Bonus Sim',
    NBN_CONNECTION_DEVICE: 'NBN Connection Device'
  },
  PAGE_LOAD_TYPE: {
    LOAD: 'Load',
    REFRESH: 'Refresh',
    VIRTUAL: 'Virtual'
  }
};

export enum CUSTOMER_STATUS {
  prospect = 'prospect',
  pending = 'pending',
  active = 'active'
}
