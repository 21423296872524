/*
 * We need a polyfill for Server-Side Rendering since Node doesn't support the
 * `EventTarget` class constructor
 *
 * @see https://www.nearform.com/blog/node-js-and-the-struggles-of-being-an-eventtarget/
 */
// import { EventTarget } from 'event-target-shim';

import { logger } from '@belong/logging/logger';

import { isBrowser } from '@belong/env';

import * as analyticsCommonEvents from './commonEvents';
import { combineAllObjects, removeFromArray, removeUndefinedFromObject } from './utils';

export * from './constants';

/**
 * the name of the CustomEvent that we'll dispatch to all subscribers
 * who've `addEventListener(EVENT_NAME)`
 */
export const EVENT_NAME = 'belong.analytics';
export const READY_EVENT_NAME = 'belong.analytics.client.ready';
/**
 * Contents of an event's payload -- should be customised to the data
 * requirements of each event.
 */
export type EventDetail = Record<string, any>;
export type AnalyticsEvent = CustomEvent<EventDetail>;
export type ExtraContext = Record<string, any>;
export type ReadyEvent = CustomEvent<{ instance: AnalyticsEvents }>;

export class AnalyticsEvents extends EventTarget {
  private contexts: ExtraContext[] = [];

  constructor() {
    super();
    if (isBrowser()) {
      window.dispatchEvent(new CustomEvent(READY_EVENT_NAME, { detail: { instance: this } }));
    }
  }

  public addContext(extraContext: ExtraContext): void {
    if (this.contexts.includes(extraContext)) {
      logger.warn('Duplicate extra context passed to analytics.addContext()');
    }

    this.contexts.push(extraContext);
  }

  public removeContext(extraContext: ExtraContext): void {
    if (!this.contexts.includes(extraContext)) {
      logger.warn('Cannot remove context that was never added.');
    }

    this.contexts = removeFromArray(this.contexts, extraContext);
  }

  /**
   * This is the main method that the application can call to push events to
   * the event stream. Any subscribers who are listening will receive the event,
   * with any extra data that's been added via `this.addContext()`
   */
  public send(details: EventDetail): void {
    // strip out all undefined values to ensure they do not override
    // any context values which are already set
    let payload = removeUndefinedFromObject(details);

    if (this.contexts.length) {
      payload = combineAllObjects(...this.contexts, payload);
    }

    const event = new CustomEvent(EVENT_NAME, {
      detail: payload
    });

    this.dispatchEvent(event);
  }

  addEventListener(eventName: string, callback): void {
    if (eventName !== EVENT_NAME) {
      throw new RangeError(`Unknown event name "${eventName}". Only "${EVENT_NAME}" is supported right now`);
    }

    super.addEventListener(eventName, callback);
  }

  removeEventListener(eventName: string, callback): void {
    if (eventName !== EVENT_NAME) {
      throw new RangeError(`Unknown event name "${eventName}". Only "${EVENT_NAME}" is supported right now`);
    }

    super.removeEventListener(eventName, callback);
  }
}
export { analyticsCommonEvents };
